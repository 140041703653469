import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenUpRightIcon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.34 14.59a9.996 9.996 0 012.589-9.661c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142a9.996 9.996 0 01-9.66 2.59M15 15V9m0 0H9m6 0L5 19"
    />
  </svg>
);
