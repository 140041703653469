import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenDownRightIcon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.41 2.34a9.996 9.996 0 019.661 2.589c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0a9.996 9.996 0 01-2.59-9.66M15 9v6m0 0H9m6 0L5 5"
    />
  </svg>
);
