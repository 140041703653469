import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenDownIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 3.338A9.996 9.996 0 0122 12c0 5.523-4.477 10-10 10S2 17.523 2 12a9.996 9.996 0 015-8.662M8 12l4 4m0 0l4-4m-4 4V2"
    />
  </svg>
);
