import React from "react";
import { IconProps } from "../icon";

export const CornerUpRightIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 20v-1.4c0-3.36 0-5.04.654-6.324a6 6 0 012.622-2.622C8.56 9 10.24 9 13.6 9H20m0 0l-5 5m5-5l-5-5"
    />
  </svg>
);
