import React from "react";
import { IconProps } from "../icon";

export const BarLineChartIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 20v-7m-8 7V10M4 20v-4m9.407-10.973l5.168 1.939M10.8 5.4L5.2 9.6m15.86-3.16a1.5 1.5 0 11-2.121 2.12 1.5 1.5 0 012.122-2.12zm-16 3a1.5 1.5 0 11-2.12 2.12 1.5 1.5 0 012.12-2.12zm8-6a1.5 1.5 0 11-2.121 2.12 1.5 1.5 0 012.122-2.12z"
    />
  </svg>
);
