import React from "react";
import { IconProps } from "../icon";

export const RefreshCw_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 10s-2.005-2.732-3.634-4.362a9 9 0 102.282 8.862M21 10V4m0 6h-6"
    />
  </svg>
);
