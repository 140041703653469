import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenRightIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.338 7A9.996 9.996 0 0112 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.996 9.996 0 01-8.662-5M12 16l4-4m0 0l-4-4m4 4H2"
    />
  </svg>
);
