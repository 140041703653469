import React from "react";
import { IconProps } from "../icon";

export const ArrowBlockRightIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 12l-7-7v4H3.8c-.28 0-.42 0-.527.055a.5.5 0 00-.219.218C3 9.38 3 9.52 3 9.8v4.4c0 .28 0 .42.054.527a.5.5 0 00.219.218C3.38 15 3.52 15 3.8 15H14v4l7-7z"
    />
  </svg>
);
