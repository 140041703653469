import React from "react";
import { IconProps } from "../icon";

export const CornerDownLeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 4v1.4c0 3.36 0 5.04-.654 6.324a6 6 0 01-2.622 2.622C15.44 15 13.76 15 10.4 15H4m0 0l5-5m-5 5l5 5"
    />
  </svg>
);
