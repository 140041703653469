import React from "react";
import { IconProps } from "../icon";

export const ArrowBlockUpIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.8 21c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218C9 20.62 9 20.48 9 20.2V10H5l7-7 7 7h-4v10.2c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C14.62 21 14.48 21 14.2 21H9.8z"
    />
  </svg>
);
