import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenUpLeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.59 21.66a9.996 9.996 0 01-9.661-2.589c-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0a9.996 9.996 0 012.59 9.66M9 15V9m0 0h6M9 9L19 19"
    />
  </svg>
);
