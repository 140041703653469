import React from "react";
import { IconProps } from "../icon";

export const CornerRightDownIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 4h3.4c3.36 0 5.04 0 6.324.654a6 6 0 012.622 2.622C16 8.56 16 10.24 16 13.6V20m0 0l-5-5m5 5l5-5"
    />
  </svg>
);
