import React from "react";
import { IconProps } from "../icon";

export const ArrowBlockLeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 12l7-7v4h10.2c.28 0 .42 0 .527.055a.5.5 0 01.218.218C21 9.38 21 9.52 21 9.8v4.4c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C20.62 15 20.48 15 20.2 15H10v4l-7-7z"
    />
  </svg>
);
