import React from "react";
import { IconProps } from "../icon";

export const RefreshCcw_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 10s.121-.85 3.636-4.364A9 9 0 0120.776 10M2 10V4m0 6h6m14 4s-.121.85-3.636 4.364A9 9 0 013.224 14M22 14v6m0-6h-6"
    />
  </svg>
);
