import React from "react";
import { IconProps } from "../icon";

export const CornerRightUpIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 20h3.4c3.36 0 5.04 0 6.324-.654a6 6 0 002.622-2.622C16 15.44 16 13.76 16 10.4V4m0 0l-5 5m5-5l5 5"
    />
  </svg>
);
