import React from "react";
import { IconProps } from "../icon";

export const ArrowCircleBrokenDownLeftIcon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9v6m0 0h6m-6 0L19 5m2.66 4.41a9.996 9.996 0 01-2.589 9.661c-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142a9.996 9.996 0 019.66-2.59"
    />
  </svg>
);
