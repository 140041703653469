import React from "react";
import { IconProps } from "../icon";

export const CornerUpLeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 14L4 9m0 0l5-5M4 9h6.4c3.36 0 5.04 0 6.324.654a6 6 0 012.622 2.622C20 13.56 20 15.24 20 18.6V20"
    />
  </svg>
);
