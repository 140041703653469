import React from "react";
import { IconProps } from "../icon";

export const Expand_06Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 8l5-5m0 0h-5m5 0v5M8 8L3 3m0 0v5m0-5h5m0 13l-5 5m0 0h5m-5 0v-5m13 0l5 5m0 0v-5m0 5h-5"
    />
  </svg>
);
