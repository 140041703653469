import React from "react";
import { IconProps } from "../icon";

export const ArrowsRightIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7h11m0 0l-4 4m4-4l-4-4M4 17h16m0 0l-4 4m4-4l-4-4"
    />
  </svg>
);
