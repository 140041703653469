import React from "react";
import { IconProps } from "../icon";

export const CornerLeftDownIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 4h-3.4c-3.36 0-5.04 0-6.324.654a6 6 0 00-2.622 2.622C8 8.56 8 10.24 8 13.6V20m0 0l5-5m-5 5l-5-5"
    />
  </svg>
);
