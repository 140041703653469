import React from "react";
import { IconProps } from "../icon";

export const FlipForwardIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 9H7.5a4.5 4.5 0 100 9H12m9-9l-4-4m4 4l-4 4"
    />
  </svg>
);
