import React from "react";
import { IconProps } from "../icon";

export const RefreshCw_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.453 12.893A8.5 8.5 0 014.638 16.25l-.25-.433m-.842-4.71A8.5 8.5 0 0119.361 7.75l.25.433M3.493 18.066l.732-2.732 2.732.732m10.085-8.132l2.732.732.732-2.732"
    />
  </svg>
);
