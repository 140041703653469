import React from "react";
import { IconProps } from "../icon";

export const RefreshCcw_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 10s2.005-2.732 3.634-4.362A9 9 0 1112 21a9.004 9.004 0 01-8.648-6.5M2 10V4m0 6h6"
    />
  </svg>
);
