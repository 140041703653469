import React from "react";
import { IconProps } from "../icon";

export const ArrowBlockDownIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 3.8c0-.28 0-.42.055-.527a.5.5 0 01.218-.219C9.38 3 9.52 3 9.8 3h4.4c.28 0 .42 0 .527.054a.5.5 0 01.218.219C15 3.38 15 3.52 15 3.8V14h4l-7 7-7-7h4V3.8z"
    />
  </svg>
);
