import React from "react";
import { IconProps } from "../icon";

export const RefreshCw_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 22s.85-.121 4.364-3.636A9 9 0 0014 3.224M14 22h6m-6 0v-6M10 2s-.85.122-4.364 3.636A9 9 0 0010 20.776M10 2H4m6 0v6"
    />
  </svg>
);
