import React from "react";
import { IconProps } from "../icon";

export const Expand_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 14v2.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C18.48 20 17.92 20 16.8 20H14M10 4H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C4 5.52 4 6.08 4 7.2V10m11-1l6-6m0 0h-6m6 0v6M9 15l-6 6m0 0h6m-6 0v-6"
    />
  </svg>
);
