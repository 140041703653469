import React from "react";
import { IconProps } from "../icon";

export const LineChartDown_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 9l6.655 4.754a.5.5 0 00.645-.053l3.4-3.402a.5.5 0 01.645-.053L21 15M6 21h12a3 3 0 003-3V6a3 3 0 00-3-3H6a3 3 0 00-3 3v12a3 3 0 003 3z"
    />
  </svg>
);
