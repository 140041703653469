import React from "react";
import { IconProps } from "../icon";

export const CornerLeftUpIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 20h-3.4c-3.36 0-5.04 0-6.324-.654a6 6 0 01-2.622-2.622C8 15.44 8 13.76 8 10.4V4m0 0l5 5M8 4L3 9"
    />
  </svg>
);
