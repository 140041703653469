import React from "react";
import { IconProps } from "../icon";

export const PieChart_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.2 14c.277 0 .416 0 .528.062a.53.53 0 01.22.243c.05.117.037.243.012.494A8 8 0 119.201 6.04c.251-.025.376-.038.494.012a.53.53 0 01.243.22c.062.112.062.251.062.528v6.4c0 .28 0 .42.055.527a.5.5 0 00.218.218c.107.055.247.055.527.055h6.4zM14 2.8c0-.277 0-.416.062-.528a.53.53 0 01.243-.22c.117-.05.243-.037.494-.012A8 8 0 0121.96 9.2c.025.251.038.376-.012.494a.53.53 0 01-.22.243c-.113.062-.251.062-.528.062h-6.4c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218C14 9.62 14 9.48 14 9.2V2.8z"
    />
  </svg>
);
