import React from "react";
import { IconProps } from "../icon";

export const ChevronSelectorHorizontalIcon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 7l-5 5 5 5m6-10l5 5-5 5" />
  </svg>
);
