import React from "react";
import { IconProps } from "../icon";

export const RefreshCcw_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.547 19.767A8.5 8.5 0 0019.362 7.75l-.25-.433M4.638 16.25A8.5 8.5 0 0115.453 4.233m-12.96 12.1l2.732.733.733-2.732m12.085-4.668l.732-2.732 2.732.732"
    />
  </svg>
);
