import React from "react";
import { IconProps } from "../icon";

export const ArrowsLeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 17H4m0 0l4 4m-4-4l4-4m12-6H9m0 0l4 4M9 7l4-4"
    />
  </svg>
);
